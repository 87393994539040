<template>
    <v-data-table
      dense
      locale="ru"
      item-key="id"
      single-line
      :headers="resultsHeaders"
      :items="dataGroupped"
      :items-per-page="100"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, -1] }"
      :hide-default-footer="false"
      class="elevation-1 accountsTable"
    >
      <template v-slot:item="{ item }">
        <tr style="height: 120px;" @click="clickHandler(item)">
          <td class="pl-2" style="max-width: 140px">{{ item.name }}</td>
          <td class="pa-1" v-html="channelsStr(item)"></td>
          <td class="pa-1" style="min-width: 140px;" v-html="personsStr(item)"></td>
          <td class="pa-1">{{ item.num }}</td>
          <td class="pa-1" style="min-width: 180px;" v-html="durationStr(item)"></td>
          <td class="pa-1" style="min-width: 110px;"><v-layout justify-center>{{ item.contacts.toLocaleString() }}</v-layout></td>
          <td class="pa-1" :style="getProgStyle(item)"><v-layout justify-center>{{ item.appeal }}%</v-layout></td>
        </tr>
      </template>
    </v-data-table>
</template>

<script>
import moment from "moment";
import { CONSTS } from "../services/constants.js";
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    filter: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    resultsHeaders: [
      {
        text: "Тема",
        value: "name",
      },
      {
        text: "Каналы",
        value: "",
      },
      {
        text: "Персоны",
        value: "",
      },
      {
        text: "Количество",
        value: "num",
        width: "130px",
      },
      {
        text: "Продолжительность",
        value: "duration",
        width: "130px",
      },
      {
        text: "Контакты",
        value: "contacts",
        width: "100px",
      },
      {
        text: "Привлекательность",
        value: "appeal",
        width: "100px",
      },
    ],
  }),

  methods: {
    clickHandler(item) {
      console.log(item)
      const emitItem = {...item, sdate: null, event: null, subject: null, tvchannel: item.name}
      this.$emit("rowClick", emitItem);
    },
    dtStart(item) {
      return moment(item.sdate).locale("ru").format("LLL");
    },
    durationStr(item) {
      return Math.round(item.duration / 60) + " мин.";
    },
    personsStr(item) {
      var maxLines = 4;
      let str = item.persons.slice(0, maxLines).join("<br>").split(" ").join("&nbsp;");
      if (item.persons.length > maxLines) str += `<br><span class="aLink">всего: ${item.persons.length}</span>`;
      return str;
    },
    channelsStr(item) {
      var maxLines = 4;
      let str = item.channels.slice(0, maxLines).join("<br>").split(" ").join("&nbsp;");
      if (item.channels.length > maxLines) str += `<br><span class="aLink">всего: ${item.channels.length}</span>`;
      return str;
    },
    getBackgroundForProg(item) {
      if (item.appeal <= -80)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[0];
      if (item.appeal <= -10)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[1];
      if (item.appeal <= 10)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[2];
      if (item.appeal <= 80)
        return CONSTS.TV_COMPARE_SLIDER_COLORS[3];
      return CONSTS.TV_COMPARE_SLIDER_COLORS[4];
    },
    getProgStyle(item) {
      const color = this.getBackgroundForProg(item);
      return `background-color: ${color}`;
    }

  },
  computed: {
    dataGroupped() {
      const data = {},
        persons = {},
        channels = {},
        durations = {},
        nums = {},
        contacts = {},
        appeals = {};
      const eventsByTags = {};
      const personsByEvents = {};
      const personsByTags = {};

      for (const item of this.data) {
        if (item.type === "tag") {
          if (!(item.tag_id in eventsByTags)) {
            eventsByTags[item.tag_id] = {};
          }
          eventsByTags[item.tag_id][item.id] = 1;
        }
        if (!(item.id in personsByEvents)) {
          personsByEvents[item.id] = {};
        }
        if (item.type === "persona") {
          personsByEvents[item.id][item.name] = 1;
        }
      }

      for (const tagId in eventsByTags) {
        for (const eventId in eventsByTags[tagId]) {
          for (const person in personsByEvents[eventId]) {
            if (!(tagId in personsByTags)) personsByTags[tagId] = {};
            personsByTags[tagId][person] = 1;
          }
        }
      }

      for (const item of this.data) {
        if (!(item.tag_id in data) && item.type === "tag") data[item.tag_id] = item;

        if (item.type === "persona") {
          if (!(item.tag_id in persons)) persons[item.tag_id] = {};
          persons[item.tag_id][item.name] = 1;
        }

        if (item.type === "tag") {
          if (!(item.tag_id in channels)) channels[item.tag_id] = {};
          channels[item.tag_id][item.tvchannel] = 1;
          if (!(item.tag_id in durations)) durations[item.tag_id] = 0;
          durations[item.tag_id] += Number(item.duration);
          if (!(item.tag_id in nums)) nums[item.tag_id] = 0;
          nums[item.tag_id] += 1;
          if (!(item.tag_id in contacts)) contacts[item.tag_id] = 0;
          contacts[item.tag_id] += Number(item.contacts);
          if (!(item.tag_id in appeals)) appeals[item.tag_id] = 0;
          appeals[item.tag_id] += Number(item.appeal * item.duration);
        }
      }

      const results = [];
      for (const item_ of Object.values(data)) {
        if (this.filter && this.filter.length && !this.filter.includes(item_.tag_id)) continue;
        const item = {...item_};
        item.channels = [];
        item.persons = [];
        if (item.tag_id in personsByTags) item.persons = [...Object.keys(personsByTags[item.tag_id])];
        if (item.tag_id in channels) item.channels = [...Object.keys(channels[item.tag_id])];
        if (item.tag_id in nums) item.num = nums[item.tag_id];
        if (item.tag_id in durations) item.duration = durations[item.tag_id];
        if (item.tag_id in contacts) item.contacts = contacts[item.tag_id];
        if (item.tag_id in appeals) item.appeal = Math.round(appeals[item.tag_id] / item.duration);
        results.push(item);
      }
      return results;
    },
  },

};
</script>

<style>
  .aLink {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
  }
</style>
